import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';


import Topbar from '../inc/Topbar';
import Navbar from '../inc/Navbar';
import Carousel from '../inc/Carousel';

import Footer from '../inc/Footer';

import $ from 'jquery';

// import {useNavigate} from "react-router-dom";

export default function Cabincrew() {

    // let navigate = useNavigate();
    
    return(
        <>
        <Topbar/>
        <Navbar/>
        {/* <Carousel/> */}

    <div className="container-fluid bg-dark p-5">
        <div className="row">
            <div className="col-12 text-center">
                <h1 className="display-4 text-white">Cabin Crew</h1>
                <Link to="/">Home</Link>
                <i className="fas fa-greater-than text-primary px-2"></i>
                <Link to="/airlines-csa">Cabin Crew</Link>
            </div>
        </div>
    </div>


        {/* <!-- About Start --> */}
    <div className="container-fluid bg-secondary p-2">
        <div className="row g-0">
            <div className="col-lg-6 py-6 px-5">
                <div className="well-middle">
                    <div className="single-well">
                    <a href="#">
                    <h4 className="sec-head">Career as a Airline Cabin Crew Jobs</h4>
                    </a>
                    <p>
                    Urgently required Cabin Crew for an airlines this job would be for flights. Only female candidates required with the age of between 18 to 30 years. There should no tattoo &amp; candidate should be medically fit.
                    </p>
                    <p>
                    Gender: Female Marital Status: Unmarried/ Married Nationality Indian & Possess a valid Indian Passport Qualification: HSC (10 +2) Weight: In Proportion to height Complexion: Clear (Scars, tattoos, pimples and blemishes not acceptable) Eyesight: Normal Language Skills: High level of fluency in written and Spoken English & Able to speak Hindi Photographs: Two full length/ Two passport size. 
                    </p>
                    <p>Need pleasing personality with excellent communication skills.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 p-2">
            <div className="well-left">
              <div className="single-well" style={{paddingTop: '40px'}}>
                <Link to="/signup">
                  <img src="assets/img/about/airlines-decon.jpg" style={{width: '100%'}} alt=""/>
                </Link>
              </div>
            </div>
            </div>

            <div className="col-12 py-0 px-5">
                  {/* <div className="well-middle">
                  <div className="single-well"> */}
                    <p style={{fontSize:'large', fontWeight: '700', marginBottom:'0', paddingTop: '30px'}}>Eligibility to Become Airline Cabin Crew</p>
                    <p className="text-justify" style={{fontSize:'11pt', paddingTop: '20px'}}>We have urgent requirements for the profile of Cabin Crew/Air Hostess with leading airlines.</p>
                    <p className="text-left" style={{paddingTop: '0px'}}>
                      <ul>
                      <li>
                          <i class="bi bi-check"></i> Position: Cabin Crew/Air Hostess
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Industry: Aviation
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Height : 5.2 or above
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Weight: Proportionate to height
                        </li>

                        <li>
                          <i class="bi bi-check"></i> Qualification: 10+2 and Above
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Experience: Fresher can also apply
                        </li>
                      </ul>
                    </p>
                    <p className="text-justify" style={{paddingTop: '20px'}}><b>Skills Required:</b> Some of the necessary skills required to become an Airline Cabin Crew are </p>
                    <p className="text-left" style={{fontWeight: '300', paddingTop: '0px'}}>
                      <ul>
                      <li>
                          <i class="bi bi-check"></i> Role: Cabin Crew
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Industry Type: Recruitment / Staffing
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Functional Area: Travel, Tours, Ticketing, Airlines
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Employment Type: Full Time, Permanent
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Role Category: Airline
                        </li>
                      </ul>
                    </p>
                 {/* </div>
                 </div>    */}
                </div>

        </div>

    </div>


    
        <Footer/>
        </>
    );

}
import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import $ from 'jquery';

export default function Carousel() {
    return(
        <>

        <div className="container-fluid p-0">
            <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img className="w-100" src="newassets/img/carousel-1.jpg" alt="Image"/>
                        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                            <div className="p-3" style={{maxWidth: '900px'}}>
                                {/* <h5 className="text-white text-uppercase">Airlines CSA Jobs</h5> */}
                                <h3 className="display-1 text-white mb-md-4 d-none">Debon Aviation Direct Training for PAN India Candidates</h3>
                                <Link to="" className="btn btn-primary py-md-3 px-md-5 me-3 rounded-pill">Register</Link>
                                <Link to="" className="btn btn-secondary py-md-3 px-md-5 rounded-pill">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="w-100" src="newassets/img/carousel-2.jpg" alt="Image"/>
                        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                            <div className="p-3" style={{maxWidth: '900px'}}>
                                {/* <h5 className="text-white text-uppercase">Airlines Ground Staff Jobs</h5> */}
                                <h3 className="display-1 text-white mb-md-4">Debon Aviation Direct Training for PAN India Candidates</h3>
                                <Link to="" className="btn btn-primary py-md-3 px-md-5 me-3 rounded-pill">Register</Link>
                                <Link to="" className="btn btn-secondary py-md-3 px-md-5 rounded-pill">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                    data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                    data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>

        </>
    );
}
import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';


import Topbar from '../inc/Topbar';
import Navbar from '../inc/Navbar';
import Carousel from '../inc/Carousel';

import Footer from '../inc/Footer';
import axios from 'axios';

import $ from 'jquery';

// import {useNavigate} from "react-router-dom";

export default function Successstory() {

    let [list,setList]=useState([]);

    async function getsuccessstory() {
        var resp=await axios.get("https://bevonairservice.co.in/decon/api/successstory");
        var data=resp.data;

        setList(data);
    }

    useEffect(()=>{
        getsuccessstory();
    },[])
    
    return(
        <>
        <Topbar/>
        <Navbar/>
        {/* <Carousel/> */}

    <div className="container-fluid bg-dark p-5">
        <div className="row">
            <div className="col-12 text-center">
                <h1 className="display-4 text-white">Success Story</h1>
                <Link to="/">Home</Link>
                <i className="fas fa-greater-than text-primary px-2"></i>
                <Link to="/successstory">Success Story</Link>
            </div>
        </div>
    </div>

    <div id="about" className="about-area area-padding">
    <div className="container">
        <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="section-headline text-center">
                <h2><span style={{color: '#db1802'}}>Success</span> Story</h2>
            </div>
        </div>
        </div>
    </div>
    </div>

        {/* <!-- About Start --> */}
        <div className="container">
        <div className="row">

        {list.map((e)=>
        <div className="col-md-3 col-sm-3 col-xs-12 text-center" style={{marginBottom: '10px'}}>
            <div className="single-team-member">
            <div className="team-img">
                
                <img src={"./uploadstoryphotos/"+e.successstory_pic} style={{borderRadius: '5px', border: '1px solid #430073'}}/>
                
            </div>
            <div className="team-content text-center">
                <h4>{e.successstory_name}</h4>
                <p>{e.successstory_age} yrs, {e.successstory_city}</p>
            </div>
            </div>
        </div>
        )}

        </div>

    </div>    


    
        <Footer/>
        </>
    );

}
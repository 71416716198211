import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';


import Topbar from '../inc/Topbar';
import Navbar from '../inc/Navbar';
import Carousel from '../inc/Carousel';

import Footer from '../inc/Footer';

import $ from 'jquery';

// import {useNavigate} from "react-router-dom";

export default function Airlinescsa() {

    // let navigate = useNavigate();
    
    return(
        <>
        <Topbar/>
        <Navbar/>
        {/* <Carousel/> */}

    <div className="container-fluid bg-dark p-5">
        <div className="row">
            <div className="col-12 text-center">
                <h1 className="display-4 text-white">Airlines CSA</h1>
                <Link to="/">Home</Link>
                <i className="fas fa-greater-than text-primary px-2"></i>
                <Link to="/airlines-csa">Airlines CSA</Link>
            </div>
        </div>
    </div>


        {/* <!-- About Start --> */}
    <div className="container-fluid bg-secondary p-2">
        <div className="row g-0">
            <div className="col-lg-6 py-6 px-5">
                <div className="well-middle">
                    <div className="single-well">
                        <Link to="#">
                        <h4 className="sec-head">Career as a Airline Customer Service Agent</h4>
                        </Link>
                        <p>
                        An Airline Customer Service Agent is a person who assists the customers with itinerary changes, flight reservations and questions about customer loyalty programs. Besides this, he also helps passengers with providing flight information, issuing tickets, check-in, solving ticket related problems and checking baggage. An Airline Customer Service Agent also upgrades the seats and reissues seats for them when no seats are available in the flight. Besides this, he also checks the boarding passes of the passengers.
                        </p>
                        <ul>
                        <li>
                            <i className="bi bi-check"></i> Responsibilities of Airline Customer Service Agent
                        </li>
                        <li>
                            <i className="bi bi-check"></i> Some of the major responsibilities of an Airline Customer Service Agent are:
                        </li>
                        <li>
                            <i className="bi bi-check"></i> Verifying and inspecting passenger documentation
                        </li>
                        <li>
                            <i className="bi bi-check"></i> Assisting passengers with self-service check-in
                        </li>
                        <li>
                            <i className="bi bi-check"></i> Making public address announcements whenever required
                        </li>
                        <li>
                            <i className="bi bi-check"></i> Maintaining high standards of security and services at the airport
                        </li>
                        
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 p-2">
            <div className="well-left">
              <div className="single-well" style={{paddingTop: '40px'}}>
                <Link to="/signup">
                  <img src="assets/img/about/airlines-decon.jpg" style={{width: '100%'}} alt=""/>
                </Link>
              </div>
            </div>
            </div>

            <div className="col-12 py-0 px-5">
                  {/* <div className="well-middle">
                  <div className="single-well"> */}
                    <p style={{fontSize:'large', fontWeight: '700', marginBottom:'0', paddingTop: '0px'}}>Eligibility to Become Airline Customer Service Agent</p>
                    <p className="text-justify" style={{fontSize:'11pt', paddingTop: '20px'}}>Check the minimum eligibility criteria for a career as an Airline Customer Service Agent provided below.</p>
                    <p className="text-left" style={{paddingTop: '0px'}}>
                      <ul>
                        <li>
                          <i className="bi bi-check"></i> Passed high school diploma or equivalent in relevant field
                        </li>
                        <li>
                          <i className="bi bi-check"></i> Employers will prefer candidates who have completed a bachelor's degree in related fields
                        </li>
                        <li>
                          <i className="bi bi-check"></i> Six months to one year of work experience is required
                        </li>
                        <li>
                          <i className="bi bi-check"></i> Knowledge of some tools such as CRM, online chat software, contact centre software, customer relationship management software and Microsoft Office.
                        </li>
                      </ul>
                    </p>
                    <p className="text-justify" style={{paddingTop: '20px'}}><b>Skills Required:</b> Some of the necessary skills required to become an Airline Customer Service Agent are </p>
                    <p className="text-left" style={{fontWeight: '300', paddingTop: '0px'}}>
                      <ul>
                        <li>
                          <i className="bi bi-check"></i> Good listening skills
                        </li>
                        <li>
                          <i className="bi bi-check"></i> Excellent communication skills
                        </li>
                        <li>
                          <i className="bi bi-check"></i> Customers service skills
                        </li>
                        <li>
                          <i className="bi bi-check"></i> Good levels of fitness
                        </li>
                        <li>
                          <i className="bi bi-check"></i> Team working skills
                        </li>
                      </ul>
                    </p>
                 {/* </div>
                 </div>    */}
                </div>

        </div>

    </div>


    
        <Footer/>
        </>
    );

}
import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';


import Topbar from '../inc/Topbar';
import Navbar from '../inc/Navbar';
import Carousel from '../inc/Carousel';

import Footer from '../inc/Footer';

import $ from 'jquery';

// import {useNavigate} from "react-router-dom";

export default function Termsofuse() {
    
    return(
        <>
        <Topbar/>
        <Navbar/>
        {/* <Carousel/> */}

    <div className="container-fluid bg-dark p-5">
        <div className="row">
            <div className="col-12 text-center">
                <h1 className="display-4 text-white">Terms of Use</h1>
                {/* <Link to="/">Home</Link>
                <i className="fas fa-greater-than text-primary px-2"></i>
                <Link to="/successstory">Success Story</Link> */}
            </div>
        </div>
    </div>

    {/* <div id="about" className="about-area area-padding">
    <div className="container">
        <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="section-headline text-center">
                <h2>Terms of Use</h2>
            </div>
        </div>
        </div>
    </div>
    </div> */}

    <div className="container">
                <div className="row">
                    
                    <div className="col-12">
                    <div className="well-middle">
                    <div className="single-well">
                        <p style={{fontSize:'large', fontWeight: '700', marginBottom:'0', paddingTop: '30px'}}>Instructions</p>
                        <p className="text-justify" style={{paddingTop: '20px'}}>Instructions are listed below::</p>
                        <p className="text-left" style={{paddingTop: '0px'}}>
                        <ul style={{fontSize:'small'}}>
                            <li>
                            <i className="fa fa-hand-o-right"></i> CANDIDATES APPLY (1 RESUME AND A PHOTO)
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> THEIR SCREENING TEST IS DONE. IF HE/SHE IS SHORTLISTED IN SCREENING TEST
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> WITHIN 72 HOURS THEIR TELEPHONIC INTERVIEW WILL BE CONDUCTED TO CHECK THEIR COMMUNICATION SKILLS AND KNOWLEDGE AS PER THE POST THEY HAVE APPLIED FOR
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> THEY WILL GET AN INTIMATION SMS ON THEIR PHONE FOR THEIR TELEPHONIC INTERVIEW ON THE DAY THEIR INTERVIEW IS SCHEDULED
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> IF HE/SHE IS SHORTLISTED IN TELEPHONIC INTERVIEW
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> THEY WILL GET A MAIL WITHIN 24 HOURS AS AN INTIMATION OF THEIR JOB ROLE AND FURTHER REQUIRED STEPS-
                            <ul style={{paddingTop: '10px', paddingLeft: '20px', paddingBottom: '10px'}}>
                                <li>
                                    <i className="bi bi-check"></i> ACCORDING TO WHICH THEY WILL SEND THEIR DOCUMENTS
                                </li>
                                <li>
                                    <i className="bi bi-check"></i> EDUCATIONAL PROOF
                                </li>
                                <li>
                                    <i className="bi bi-check"></i> ADDRESS PROOF
                                </li>
                                <li>
                                    <i className="bi bi-check"></i> ID PROOF
                                </li>
                                <li>
                                    <i className="bi bi-check"></i> PHOTOS (BOTH PASSPORT AND POSTCARD SIZE) &AMP; VERIFICATION AND REGISTRATION FEES Rs.1425/-
                                </li>
                            </ul>
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> THEY WILL RECEIVE A REGISTRATION FORM AND A PREPARATION MODULE IN THEIR MENTIONED MAIL ID, SO THAT THEY CAN PREPARE THEM-SELF FOR THE FINAL ROUND OF INTERVIEW
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> WITHIN 15 WORKING DAYS THEY WILL GET A CONFIRMATION ABOUT THEIR FINAL INTERVIEW
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> THEY WILL GET AN OPTION FOR TRAINING ALSO FROM OUR SIDE IF THEY ARE INTERESTED FOR PROFESSIONAL TRAINING, THEY CAN DROP THE FINAL INTERVIEW AND GO FOR IT THROUGH OUR TRAINING PARTNERS ACCORDING TO THEIR WISH WHICH WILL BE CHARGEABLE
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> IF THEY GO FOR FINAL INTERVIEW
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> IF THEY GO FOR TRAINING
                            <ul style={{paddingTop: '10px', paddingLeft: '20px', paddingBottom: '10px'}}>
                                <li>
                                    <i className="bi bi-check"></i> THEIR INTERVIEW WOULD BE CONDUCTED AS PER SCHEDULE
                                </li>
                                <li>
                                    <i className="bi bi-check"></i> THEIR TRAINING WOULD BE CONDUCTED AT DELHI, MUMBAI AND JAIPUR FOR 45 DAYS
                                </li>
                                <li>
                                    <i className="bi bi-check"></i> FINAL ROUND CAN BE A FACE TO FACE INTERVIEW OR A ONLINE INTERVIEW OR A TELEPHONIC INTERVIEW (DEPENDING UPON THE CLIENTS REQUIREMENT AND IT CAN BE CONDUCTED EITHER BY THE BUSINESS OR BY OUR SIDE AS PER THE BUSINESS REQUIREMENT)
                                </li>
                            </ul>
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> ON COMPLETION OF THE TRAINING THE CANDIDATES WILL BE GIVING INTERVIEWS AND THEY WILL GET THE PLACEMENT AS PER THEIR ELIGIBILITY AND SKILLS
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> 100% ASSURED JOB IF THEY CLEAR FINAL ROUND
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> 100% ASSURED JOB ON COMPLETION OF THE TRAINING
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> We help Candidates to secure their future in 2 ways mentioned above
                            <ul style={{paddingTop: '10px', paddingLeft: '20px', paddingBottom: '10px'}}>
                                <li>
                                    <i className="bi bi-check"></i> Training
                                </li>
                                <li>
                                    <i className="bi bi-check"></i> Final Interview
                                </li>
                            </ul>
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> It's simple and straight; if the candidate feels confident enough to clear the final interview they can chose Direct Interview or if they want to take Training they can skip Direct Interview and join Training.
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> We provide 100% assured job
                            </li>
                        </ul>
                        </p>
                        <p style={{fontSize:'large', fontWeight: '700', marginBottom:'0', paddingTop: '30px'}}>Rules &amp; Regulations</p>
                        
                        <p className="text-left" style={{fontSize:'10pt', fontWeight: '300', paddingTop: '0px'}}>
                        <ul>
                            <li>
                            <i className="fa fa-hand-o-right"></i> 100% Job assistance is guaranteed
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> It takes 15 working days for the Documentation and Registration process
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> No call on Sunday's and Holidays (If there is any urgency, you can leave a message)
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> Interviews can be conducted face to face, Telephonic or online depending upon the Company's Business Requirement
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> The Company may cancel Candidates profile if any fault or false statement is either given or mentioned by the candidate
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> Under any situation use of Abusive Language or profanity may lead to cancellation of Candidates profile
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> If in any case we are unable to receive your call, kindly drop us a text or mail us, we will get back to you ASAP
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> In case of any issues you can reach us using the CONTACT US option or can contact us in the specific phone no's provided to you
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> The job location depends on the Companies Business Requirement; Candidates should be open to relocate in PAN INDIA Basis
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> Any amount charged from the Candidates is only for the Documentation and Registration or Training fees, and is non-refundable
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> Final Interviews will only be conducted once, it can be Re-Scheduled before the date of interview but cannot be conducted twice for any possible reason
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> Training will only be given in Delhi, Mumbai and Jaipur
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> Post Training the job location will be in Delhi, Mumbai and Jaipur
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> After joining if any Candidate wants to back out it will be their responsibility not the company
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> Candidates will be given minimum 1 to maximum 3 Job Opportunities
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> If the candidates reject Job Opportunities, the company holds no responsibility for their Job
                            </li>
                            <li>
                            <i className="fa fa-hand-o-right"></i> In case any candidate is unable to clear Final round of Interview his candidature would be terminated and he cannot apply in our company for next 6 months
                            </li>
                        </ul>
                        </p>
                    </div>
                    </div>   
                    </div>
                </div>
            </div> 


    
        <Footer/>
        </>
    );

}
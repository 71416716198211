import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';


import Topbar from '../inc/Topbar';
import Navbar from '../inc/Navbar';
import Carousel from '../inc/Carousel';

import Footer from '../inc/Footer';

import $ from 'jquery';

// import {useNavigate} from "react-router-dom";

export default function Groundstaff() {

    // let navigate = useNavigate();
    
    return(
        <>
        <Topbar/>
        <Navbar/>
        {/* <Carousel/> */}

    <div className="container-fluid bg-dark p-5">
        <div className="row">
            <div className="col-12 text-center">
                <h1 className="display-4 text-white">Ground Staff</h1>
                <Link to="/">Home</Link>
                <i className="fas fa-greater-than text-primary px-2"></i>
                <Link to="/ground-staff">Ground Staff</Link>
            </div>
        </div>
    </div>


        {/* <!-- About Start --> */}
    <div className="container-fluid bg-secondary p-2">
        <div className="row g-0">
            <div className="col-lg-6 py-6 px-5">
                <div className="well-middle">
                    <div className="single-well">
                    <Link to="#">
                    <h4 className="sec-head">Career as a Airline Ground Staff Jobs</h4>
                    </Link>
                    <p>
                    We are looking for Dynamic Candidate with good Communication Skill. Candidates who are looking for a career in ground staff must have cleared 12th from any recognized institution. Implementation of corrective and/or counter measures as ordered by responsible Ground operations department. Ensure all local procedures in regards to safety and security.
                    </p>
                    <p>
                    Taking care of the safety and security of the passengers on flights. Need to Serve Meals on Board to the Customers with a Hospitality Attitude. Person should have unblemished complexion and good body language. Excellent verbal and written communication skills in both Hindi and English.
                    </p>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 p-2">
            <div className="well-left">
              <div className="single-well" style={{paddingTop: '40px'}}>
                <Link to="/signup">
                  <img src="assets/img/about/airlines-decon.jpg" style={{width: '100%'}} alt=""/>
                </Link>
              </div>
            </div>
            </div>

            <div className="col-12 py-0 px-5">
                  {/* <div className="well-middle">
                  <div className="single-well"> */}
                    <p style={{fontSize:'large', fontWeight: '700', marginBottom:'0', paddingTop: '10px'}}>Eligibility to Become Airline Cabin Crew</p>
                    <p className="text-justify" style={{fontSize:'11pt', paddingTop: '20px'}}>We have urgent requirements for the profile of Cabin Crew/Air Hostess with leading airlines.</p>
                    <p className="text-left" style={{paddingTop: '0px'}}>
                      <ul>
                      <li>
                          <i class="bi bi-check"></i> Position: Ground Staff
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Age: 18-27 yrs
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Male- 5.6 and above, Female 5.3
                        </li>
                        <li>
                          <i class="bi bi-check"></i> No visible Tattoos / Scars
                        </li>

                        <li>
                          <i class="bi bi-check"></i> Well Groomed
                        </li>
                      </ul>
                    </p>
                    <p className="text-justify" style={{paddingTop: '20px'}}><b>Skills Required:</b> Some of the necessary skills required to become an Airline Ground Staff are </p>
                    <p className="text-left" style={{fontWeight: '300', paddingTop: '0px'}}>
                      <ul>
                      <li>
                          <i class="bi bi-check"></i> Good understanding of Ground support equipment handling
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Be able to interact with various internal and external agencies
                        </li>
                        <li>
                          <i class="bi bi-check"></i> High level of computer literacy with good knowledge of Microsoft word/Excel/Access preferred.
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Well-developed written and spoken communication skills in English
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Ability to deal with difficult situations tactfully and diplomatically
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Must be Organized, Disciplined and Result Oriented
                        </li>
                      </ul>
                    </p>
                 {/* </div>
                 </div>    */}
                </div>

        </div>

    </div>


    
        <Footer/>
        </>
    );

}
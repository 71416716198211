import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';


import Topbar from '../inc/Topbar';
import Navbar from '../inc/Navbar';
import Carousel from '../inc/Carousel';

import Footer from '../inc/Footer';

import $ from 'jquery';

// import {useNavigate} from "react-router-dom";

export default function Main() {

    // let navigate = useNavigate();
    
    return(
        <>
        <Topbar/>
        <Navbar/>
        <Carousel/>


        {/* <!-- About Start --> */}
    <div className="container-fluid bg-secondary p-0">
        <div className="row g-0">
            <div className="col-lg-6 py-6 px-5">
                <h1 className="mb-4" style={{fontSize: '30px'}}>Welcome To <span className="text-primary">BEVON AIR SERVICE</span></h1>
                {/* <h4 className="text-primary mb-4">Diam dolor diam ipsum sit. Clita erat ipsum et lorem stet no lorem sit clita duo justo magna dolore</h4> */}
                <p className="mb-4">Bevon Air Service is a leader in the recruiting and staffing industry. We are a training agency that works solely with aircraft maintenance, modification, and repair platforms. What makes Aviation training unique is our focus on aviation maintenance skill sets. It is our goal to deliver the perfect fit between our candidates and employers. In addition, our recruiting process is driven by a sophisticated system that enables us to recruit, employ and dispatch a broad range of aviation employees to job sites quickly and reliably. This is the foundation that has made Aviation Training who we are today.</p>
                {/* <a href="" className="btn btn-primary py-md-3 px-md-5 rounded-pill">Get A Quote</a> */}
            </div>
            <div className="col-lg-6">
                <div className="h-100 d-flex flex-column justify-content-center bg-primary p-5">
                    <div className="d-flex text-white mb-5">
                        <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white text-primary rounded-circle mx-auto mb-4" style={{width:'60px', height: '60px'}}>
                            <i className="fa fa-user-tie fs-4"></i>
                        </div>
                        <div className="ps-4">
                            <h3>Direct Training</h3>
                            <p className="mb-0">SKILL : TEAM LEADING & GOOD COMMUNICATION+PERSONALITY. Hiring Immediately! Multiple Airport Staff Positions Available.</p>
                        </div>
                    </div>
                    <div className="d-flex text-white mb-5">
                        <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white text-primary rounded-circle mx-auto mb-4" style={{width:'60px', height: '60px'}}>
                            <i className="fa fa-chart-line fs-4"></i>
                        </div>
                        <div className="ps-4">
                            <h3>Recruiter Training to Keep Candidates</h3>
                            <p className="mb-0">We train and accompany your recruiters to create an inclusive hiring and workspace with candidates from our communities</p>
                        </div>
                    </div>
                    <div className="d-flex text-white">
                        <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white text-primary rounded-circle mx-auto mb-4" style={{width:'60px', height: '60px'}}>
                            <i className="fa fa-balance-scale fs-4"></i>
                        </div>
                        <div className="ps-4">
                            <h3>Urgent Hiring For Airport Jobs</h3>
                            <p className="mb-0">Catch the attention of top recruiters, stand out from thousands of candidates Register Now.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- About End -->
    

    <!-- Services Start --> */}
    <div className="container-fluid pt-6 px-5">
        <div className="text-center mx-auto mb-5" style={{maxWidth: '600px'}}>
            <h1 className="display-5 mb-0">What We Offer</h1>
            <hr className="w-25 mx-auto bg-primary"/>
        </div>
        <div className="row g-5">
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                    <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width:'90px', height: '90px'}}>
                        <i className="fa fa-user-tie fa-2x"></i>
                    </div>
                    <h3 className="mb-3">Airlines CSA Jobs</h3>
                    <p className="mb-0">An Airline Customer Service Agent is a person who assists the customers with itinerary changes, flight reservations and questions about customer loyalty programs</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                    <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width:'90px', height: '90px'}}>
                        <i className="fa fa-chart-pie fa-2x"></i>
                    </div>
                    <h3 className="mb-3">Ground Operations Staff</h3>
                    <p className="mb-0">We are looking for Dynamic Candidate with good Communication Skill. Candidates who are looking for a career in ground staff must have cleared 12th from any recognized</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                    <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width:'90px', height: '90px'}}>
                        <i className="fa fa-chart-line fa-2x"></i>
                    </div>
                    <h3 className="mb-3">Airline Baggage Handler</h3>
                    <p className="mb-0">We are hiring candidates, vacancy for airport baggage handler executive on PAN INDIA candidates. The baggage handling system at an airport</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                    <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width:'90px', height: '90px'}}>
                        <i className="fa fa-chart-area fa-2x"></i>
                    </div>
                    <h3 className="mb-3">Airline Cabin Crew</h3>
                    <p className="mb-0">Urgently required Cabin Crew for an airlines this job would be for flights. Only female candidates required with the age of between 18 to 30 years.</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                    <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width:'90px', height: '90px'}}>
                        <i className="fa fa-balance-scale fa-2x"></i>
                    </div>
                    <h3 className="mb-3">Airport Retails</h3>
                    <p className="mb-0">We are hiring candidates, vacancy for airport retails executive on PAN INDIA candidates. As the public face of the company the Retail Associate</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                    <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width:'90px', height: '90px'}}>
                        <i className="fa fa-house-damage fa-2x"></i>
                    </div>
                    <h3 className="mb-3">Reservation & Ticketing Officer</h3>
                    <p className="mb-0">Work on the various front offices client tools as required for the specific queues and provide customer service through calls. Understand customer queries</p>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Services End -->


    <!-- Features Start --> */}
    <div className="container-fluid py-6 px-5">
        <div className="text-center mx-auto mb-5" style={{maxWidth: '600px'}}>
            <h1 className="display-5 mb-0">Why Choose Us!!!</h1>
            <hr className="w-25 mx-auto bg-primary"/>
        </div>
        <div className="row g-5">
            <div className="col-lg-4">
                <div className="row g-5">
                    <div className="col-12">
                        <div className="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3" style={{width:'60px', height: '60px'}}>
                            <i className="fa fa-cubes fs-4 text-white"></i>
                        </div>
                        <h3>Best In Industry</h3>
                        <p className="mb-0">Magna sea eos sit dolor, ipsum amet ipsum lorem diam dolor eos diam et diam dolor ea</p>
                    </div>
                    <div className="col-12">
                        <div className="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3" style={{width:'60px', height: '60px'}}>
                            <i className="fa fa-percent fs-4 text-white"></i>
                        </div>
                        <h3>99% Success Rate</h3>
                        <p className="mb-0">Magna sea eos sit dolor, ipsum amet ipsum lorem diam dolor eos diam et diam dolor ea</p>
                    </div>
                    <div className="col-12">
                        <div className="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3" style={{width:'60px', height: '60px'}}>
                            <i className="fa fa-award fs-4 text-white"></i>
                        </div>
                        <h3>Award Winning</h3>
                        <p className="mb-0">Magna sea eos sit dolor, ipsum amet ipsum lorem diam dolor eos diam et diam dolor ea</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="d-block bg-primary h-100 text-center">
                    <img className="img-fluid" src="img/feature.jpg" alt=""/>
                    <div className="p-4">
                        <p className="text-white mb-4">Freshers can apply for the job in Aviation and get salary of 18000 ... Create your profile! One click to apply for jobs; Better matching jobs for you</p>
                        <a href="" className="btn btn-light py-md-3 px-md-5 rounded-pill mb-2">Learn More</a>
                    </div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="row g-5">
                    <div className="col-12">
                        <div className="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3" style={{width:'60px', height: '60px'}}>
                            <i className="far fa-smile-beam fs-4 text-white"></i>
                        </div>
                        <h3>100% Happy Client</h3>
                        <p className="mb-0">Magna sea eos sit dolor, ipsum amet ipsum lorem diam dolor eos diam et diam dolor ea</p>
                    </div>
                    <div className="col-12">
                        <div className="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3" style={{width:'60px', height: '60px'}}>
                            <i className="fa fa-user-tie fs-4 text-white"></i>
                        </div>
                        <h3>Professional Advisors</h3>
                        <p className="mb-0">Magna sea eos sit dolor, ipsum amet ipsum lorem diam dolor eos diam et diam dolor ea</p>
                    </div>
                    <div className="col-12">
                        <div className="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3" style={{width:'60px', height: '60px'}}>
                            <i className="fa fa-headset fs-4 text-white"></i>
                        </div>
                        <h3>24/7 Customer Support</h3>
                        <p className="mb-0">Magna sea eos sit dolor, ipsum amet ipsum lorem diam dolor eos diam et diam dolor ea</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Features Start -->


    <!-- Quote Start --> */}
    <div className="container-fluid bg-secondary px-0">
        <div className="row g-0">
            <div className="col-lg-6 py-6 px-5">
                <h1 className="display-5 mb-4">Request A Free Quote</h1>
                <p className="mb-4">Kasd vero erat ea amet justo no stet, et elitr no dolore no elitr sea kasd et dolor diam tempor. Nonumy sed dolore no eirmod sit nonumy vero lorem amet stet diam at. Ea at lorem sed et, lorem et rebum ut eirmod gubergren, dolor ea duo diam justo dolor diam ipsum dolore stet stet elitr ut. Ipsum amet labore lorem lorem diam magna sea, eos sed dolore elitr.</p>
                <form>
                    <div className="row gx-3">
                        <div className="col-6">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="form-floating-1" placeholder="John Doe"/>
                                <label for="form-floating-1">Full Name</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <input type="email" className="form-control" id="form-floating-2" placeholder="name@example.com"/>
                                <label for="form-floating-2">Email address</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <input type="text" maxLength={10} className="form-control" id="form-floating-2" placeholder="name@example.com"/>
                                <label for="form-floating-2">Phone number</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-floating">
                                <select className="form-select" id="floatingSelect" aria-label="Select Your Job">
                                    <option value="">Select Your Job</option>
                                    <option value="Airlines CSA">Airlines CSA</option>
                                    <option value="Cabin Crew">Cabin Crew</option>
                                    <option value="Ground Staff">Ground Staff</option>
                                    <option value="Baggage Handler">Baggage Handler</option>
                                    <option value="Airport Retail">Airport Retail</option>
                                    <option value="Airlines Reservation">Airlines Reservation</option>
                                </select>
                                <label for="floatingSelect">Select Your Job</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <button className="btn btn-primary w-100 h-100" type="submit">Request A Quote</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-lg-6" style={{minHeight: '400px'}}>
                <div className="position-relative h-100">
                    <img className="position-absolute w-100 h-100" src="newassets/img/quote.jpg" style={{objectFit: 'cover'}} />
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Quote End --> */}

        <Footer/>
        </>
    );

}
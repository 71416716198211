

import {Link} from 'react-router-dom';

import {useEffect} from 'react';

function Footer() {

    return(
        <>
        {/* <footer className="footMap">
            <div className="footer-area">
            <div className="container">
                <div className="row">
                <div className="col-md-4">
                    <div className="footer-content">
                    <div className="footer-head">
                        <div className="footer-logo">

                        <Link to="index"><img src="./assets/img/logo.png" className="img-fluid"/></Link>
                        </div>
                        <p style={{padding: '8px', borderRadius: '10px', border: '1px dotted #222', backgroundColor: 'rgba(138, 210, 255, 0.2)'}}>
                        <b><span style={{fontSize: 'large'}}>LICENCE</span></b><br/><br/>
                        Licence Under Section 201<br/>
                        The West Bengal Municipal Act, 1993<br/>
                        (West Bengal Act. XXII of 1993)<br/>
                        (See Section 118)<br/><br/>
                        <b>Certificate No: 2002718379</b><br/>
                        <b>Enlistment No: 200272021086278</b>
                        </p>
                        <p>Urgent Looking Staff for Freshers. Smart Candidates for Domestic &amp; International Airlines.</p>
                        <div className="footer-icons">
                        <ul>
                            <li>
                            <Link to="#"><i className="bi bi-facebook"></i></Link>
                            </li>
                            <li>
                            <Link to="#"><i className="bi bi-twitter"></i></Link>
                            </li>
                            <li>
                            <Link to="#"><i className="bi bi-instagram"></i></Link>
                            </li>
                            <li>
                            <Link to="#"><i className="bi bi-linkedin"></i></Link>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="footer-content">
                    <div className="footer-head">
                        <h4>information</h4>
                        <p>
                        Direct Training in Aviation Sector.
                        </p>
                        <div className="footer-contacts">
                        <p><span>Tel:</span> +91-9073331533 (Mr. Devesh Verma)</p>
                        <p><span>Tel:</span> +91-9073331733 (Mr. Kaustav Srivastav)</p>
                        <p><span>Email:</span> support@deconaviation.co.in</p>
                        <p><span>Working Hours:<br/></span>Mon-Sat: 10am-6pm<br/>Sunday Closed</p>
                        </div>
                    </div>
                    </div>
                </div>


                <div className="col-md-4">
                    <div className="footer-content">
                    <div className="footer-head">
                        <h4>Job Profile</h4>
                        
                        <Link to="/airlines-csa"><i className="fa fa-dot-circle-o"></i> Airlines Customer Service Agent</Link>
                        <br/>
                        <Link to="/ground-staff"><i className="fa fa-dot-circle-o"></i> Ground Staff</Link>
                        <br/>
                        <Link to="/cabin-crew"><i className="fa fa-dot-circle-o"></i> Cabin Crew</Link>
                        <br/>
                        <Link to="/baggage-handler"><i className="fa fa-dot-circle-o"></i> Baggage handler</Link>
                        <br/>
                        <Link to="/airport-retail"><i className="fa fa-dot-circle-o"></i> Airport Retails</Link>
                        <br/>
                        <Link to="/airlines-reservation"><i className="fa fa-dot-circle-o"></i> Airlines Reservation</Link>

                    </div>
                    </div>
                </div>

                </div>
            </div>
            </div>
            <div className="footer-area-bottom">
            <div className="container">
                <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="copyright text-center">
                    <p>
                        &copy; Copyright <strong>DeconAviation</strong>. All Rights Reserved
                    </p>
                    </div>
                    <div className="credits">
                    
                    </div>
                </div>
                </div>
            </div>
            </div>
        </footer> */}
        <div className="container-fluid bg-primary text-secondary p-5">
            <div className="row g-5">
                <div className="col-12 text-center">
                    <h1 className="display-5 mb-4">Stay Update!!!</h1>
                    <form className="mx-auto" style={{maxWidth: '600px'}}>
                        {/* <div className="input-group">
                            <input type="text" className="form-control border-white p-3" placeholder="Your Email"/>
                            <button className="btn btn-dark px-4">Sign Up</button>
                        </div> */}
                    </form>
                </div>
            </div>
        </div>
        <div className="container-fluid bg-dark text-secondary p-5">
            <div className="row g-5">
                <div className="col-lg-2 col-md-6">
                    <h3 className="text-white mb-4">Important</h3>
                    <div className="d-flex flex-column justify-content-start">
                        <Link className="text-secondary mb-2" to= "/"><i className="bi bi-arrow-right text-primary me-2"></i>Home</Link>
                        <Link className="text-secondary mb-2" to= "/about"><i className="bi bi-arrow-right text-primary me-2"></i>About</Link>
                        <Link className="text-secondary mb-2" to= "/airlines-csa"><i className="bi bi-arrow-right text-primary me-2"></i>CSA</Link>
                        <Link className="text-secondary mb-2" to= "/ground-staff"><i className="bi bi-arrow-right text-primary me-2"></i>Ground Staff</Link>
                        <Link className="text-secondary mb-2" to= "/cabin-crew"><i className="bi bi-arrow-right text-primary me-2"></i>Cabin Crew</Link>
                        {/* <Link className="text-secondary" to= "#"><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</Link> */}
                    </div>
                </div>
                <div className="col-lg-2 col-md-6">
                    <h3 className="text-white mb-4">Other Links</h3>
                    <div className="d-flex flex-column justify-content-start">
                        <Link className="text-secondary mb-2" to= "/tos"><i className="bi bi-arrow-right text-primary me-2"></i>TOS</Link>
                        <Link className="text-secondary mb-2" to= "/privacy-policy"><i className="bi bi-arrow-right text-primary me-2"></i>Privacy</Link>
                        <Link className="text-secondary mb-2" to= "/disclaimer"><i className="bi bi-arrow-right text-primary me-2"></i>Disclaimer</Link>
                        <Link className="text-secondary" to= "/contact"><i className="bi bi-arrow-right text-primary me-2"></i>Contact</Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <p style={{padding: '8px', borderRadius: '10px', border: '1px dotted #222', backgroundColor: 'rgba(138, 210, 255, 0.2)'}}>
                    <b><span style={{fontSize: 'large'}}>LICENCE</span></b><br/><br/>
                    Licence Under Section 201<br/>
                    The West Bengal Municipal Act, 1993<br/>
                    (West Bengal Act. XXII of 1993)<br/>
                    (See Section 118)<br/><br/>
                    <b>Certificate No: 2002718379</b><br/>
                    <b>Enlistment No: 200272021086278</b>
                    </p>
                    <p>Urgent Looking Staff for Freshers. Smart Candidates for Domestic &amp; International Airlines.</p>
                </div>
                <div className="col-lg-4 col-md-6">
                    <h3 className="text-white mb-4">Follow Us</h3>
                    <div className="footer-content">
                    <div className="footer-head">
                        <p>
                        Direct Training in Aviation Sector.
                        </p>
                        <div className="footer-contacts">
                        <p><span>Tel:</span> +91-9073331533 (Mr. Devesh Verma)</p>
                        <p><span>Tel:</span> +91-9073331733 (Mr. Kaustav Srivastav)</p>
                        <p><span>Email:</span> support@bevonairservice.co.in</p>
                        <p><span>Working Hours:<br/></span>Mon-Sat: 10am-6pm<br/>Sunday Closed</p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="container-fluid bg-dark text-secondary text-center border-top py-4 px-5" style={{borderColor: 'rgba(256, 256, 256, .1) !important'}}>
            <p className="m-0">&copy; <Link className="text-secondary border-bottom" to= "#">Your Site Name</Link>. All Rights Reserved. Designed by <Link className="text-secondary border-bottom" to= "https://htmlcodex.com">HTML Codex</Link></p>
        </div> */}
        </>
    );
}

export default Footer;
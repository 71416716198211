import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';


import Topbar from '../inc/Topbar';
import Navbar from '../inc/Navbar';
import Carousel from '../inc/Carousel';

import Footer from '../inc/Footer';

import $ from 'jquery';

// import {useNavigate} from "react-router-dom";

export default function About() {

    // let navigate = useNavigate();
    
    return(
        <>
        <Topbar/>
        <Navbar/>
        {/* <Carousel/> */}

    <div className="container-fluid bg-dark p-5">
        <div className="row">
            <div className="col-12 text-center">
                <h1 className="display-4 text-white">About Us</h1>
                <Link to="./">Home</Link>
                <i className="fas fa-greater-than text-primary px-2"></i>
                <Link to="./about">About</Link>
            </div>
        </div>
    </div>


        {/* <!-- About Start --> */}
    <div className="container-fluid bg-secondary p-2">
        <div className="row g-0">
            <div className="col-lg-6 py-6 px-5">
                <h1 className="mb-4" style={{fontSize: '30px'}}>Welcome To <span className="text-primary">BEVON AIR SERVICE</span></h1>
                {/* <h4 className="text-primary mb-4">Diam dolor diam ipsum sit. Clita erat ipsum et lorem stet no lorem sit clita duo justo magna dolore</h4> */}
                <p className="mb-4">BEVON AIR SERVICE is a leader in the recruiting and staffing industry. We are a training agency that works solely with aircraft maintenance, modification, and repair platforms. What makes Aviation training unique is our focus on aviation maintenance skill sets. It is our goal to deliver the perfect fit between our candidates and employers. In addition, our recruiting process is driven by a sophisticated system that enables us to recruit, employ and dispatch a broad range of aviation employees to job sites quickly and reliably. This is the foundation that has made Aviation Training who we are today.</p>
                {/* <a href="" className="btn btn-primary py-md-3 px-md-5 rounded-pill">Get A Quote</a> */}
            </div>
            <div className="col-lg-6">
                <div className="h-100 d-flex flex-column justify-content-center bg-primary p-5">
                    <div className="d-flex text-white mb-5">
                        <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white text-primary rounded-circle mx-auto mb-4" style={{width:'60px', height: '60px'}}>
                            <i className="fa fa-user-tie fs-4"></i>
                        </div>
                        <div className="ps-4">
                            <h3>Direct Training</h3>
                            <p className="mb-0">SKILL : TEAM LEADING & GOOD COMMUNICATION+PERSONALITY. Hiring Immediately! Multiple Airport Staff Positions Available.</p>
                        </div>
                    </div>
                    <div className="d-flex text-white mb-5">
                        <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white text-primary rounded-circle mx-auto mb-4" style={{width:'60px', height: '60px'}}>
                            <i className="fa fa-chart-line fs-4"></i>
                        </div>
                        <div className="ps-4">
                            <h3>Recruiter Training to Keep Candidates</h3>
                            <p className="mb-0">We train and accompany your recruiters to create an inclusive hiring and workspace with candidates from our communities</p>
                        </div>
                    </div>
                    <div className="d-flex text-white">
                        <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white text-primary rounded-circle mx-auto mb-4" style={{width:'60px', height: '60px'}}>
                            <i className="fa fa-balance-scale fs-4"></i>
                        </div>
                        <div className="ps-4">
                            <h3>Urgent Hiring For Airport Jobs</h3>
                            <p className="mb-0">Catch the attention of top recruiters, stand out from thousands of candidates Register Now.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

    <div className="container p-2">
        <div className="row">
            <div className="col-12">
                <p className="text-left" style={{fontSize:'12pt', fontWeight: '700', paddingTop: '20px'}}>100% Job Assurance only by DECON AVIATION – Find Below all trainings:-</p>
                <p className="text-left" style={{fontSize:'12pt', paddingTop: '10px'}}>The training cell at DECON AVIATION Industry [Airport Jobs/ Airport jobs in India /Jobs in dumdum airport/ Airport jobs in kolkata /Airline Jobs/ Airport job consultancy/ Airport Retails Shops/ Airport Lounges / Air Cargo / Air Logistics / Air Travel / Air Tourism / Consultancy for airport jobs / Hospitality sector Jobs] to provide wide career opportunities through various reputed Airlines / Airline corporate offices / Airline Ticketing Offices / Airline Back Offices / Airline MNC's / Airline BPO's related with Airline process.</p>
                <p className="text-left" style={{fontSize:'12pt', paddingTop: '10px'}}>It is our immense pleasure to announce that DECON AVIATION candidates have been placed in various reputed Airlines &amp; Airport Ground Handling Companies. To name a few: Indigo, Spicejet, Air India, Qatar Airways, Bhadra Aviation, Cambata Aviation, Decor Aviation, Thomas cook, Cox and kings, Jet Airways, GVK, GMR, Air India Sats, w h smith ,Realy,etc. Our students have proved themselves and have become integral part of these reputed organizations.</p>
            </div>
        </div>
    </div>



    
        <Footer/>
        </>
    );

}
import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';


import Topbar from '../inc/Topbar';
import Navbar from '../inc/Navbar';
import Carousel from '../inc/Carousel';

import Footer from '../inc/Footer';

import $ from 'jquery';

// import {useNavigate} from "react-router-dom";

export default function Baggagehandler() {

    // let navigate = useNavigate();
    
    return(
        <>
        <Topbar/>
        <Navbar/>
        {/* <Carousel/> */}

    <div className="container-fluid bg-dark p-5">
        <div className="row">
            <div className="col-12 text-center">
                <h1 className="display-4 text-white">Baggage Handler</h1>
                <Link to="/">Home</Link>
                <i className="fas fa-greater-than text-primary px-2"></i>
                <Link to="/baggage-handler">Baggage Handler</Link>
            </div>
        </div>
    </div>


        {/* <!-- About Start --> */}
    <div className="container-fluid bg-secondary p-2">
        <div className="row g-0">
            <div className="col-lg-6 py-6 px-5">
                <div className="well-middle">
                    <div className="single-well">
                    <Link to="#">
                    <h4 className="sec-head">Career as a Airline Baggage Handler Jobs</h4>
                    </Link>
                    <p>
                    We are hiring candidates, vacancy for airport baggage handler executive on PAN INDIA candidates. Hiring Fresher Higher Secondary Pass people in leading airlines companies.
                    </p>
                    <p>
                    The baggage handling system at an airport plays a crucial role in keeping travelers happy. A baggage-handling system has three main jobs:
                    </p>
                    <ul>
                        <li>
                            <i class="bi bi-check"></i> Move bags from the check-in area to the departure gate
                        </li>
                        <li>
                            <i class="bi bi-check"></i> Move bags from one gate to another during transfers
                        </li>
                        <li>
                            <i class="bi bi-check"></i> Move bags from the arrival gate to the baggage-claim
                        </li>
                    </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 p-2">
            <div className="well-left">
              <div className="single-well" style={{paddingTop: '40px'}}>
                <Link to="/signup">
                  <img src="assets/img/about/airlines-decon.jpg" style={{width: '100%'}} alt=""/>
                </Link>
              </div>
            </div>
            </div>

            <div className="col-12 py-0 px-5">
                  {/* <div className="well-middle">
                  <div className="single-well"> */}
                    <p style={{fontSize:'large', fontWeight: '700', marginBottom:'0', paddingTop: '30px'}}>Eligibility to Become Airline Baggage Handler</p>
                    <p className="text-justify" style={{fontSize:'11pt', paddingTop: '20px'}}>We have urgent requirements for the profile of Baggage Handler with leading airlines.</p>
                    <p className="text-left" style={{paddingTop: '0px'}}>
                      <ul>
                        <li>
                          <i class="bi bi-check"></i> Position: Ground Staff
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Age: 18-33 yrs
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Location: PAN India.
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Qualification: 12th pass or above.
                        </li>

                        <li>
                          <i class="bi bi-check"></i> Job Types: Full-time, Fresher
                        </li>
                      </ul>
                    </p>
                    <p className="text-justify" style={{paddingTop: '20px'}}><b>Skills Required:</b> Some of the necessary skills required to become an Airline Baggage Handler are </p>
                    <p className="text-left" style={{fontWeight: '300', paddingTop: '0px'}}>
                      <ul>
                      <li>
                          <i class="bi bi-check"></i> Good communication skill in English and Hindi is required
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Airline Executive
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Air Ticketing
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Direct Pay-roll Full time Permanent Job.
                        </li>
                      </ul>
                    </p>
                 {/* </div>
                 </div>    */}
                </div>

        </div>

    </div>


    
        <Footer/>
        </>
    );

}
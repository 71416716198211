import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import $ from 'jquery';

import axios from 'axios';

export default function Topbar() {

    let [list,setList]=useState("");

    async function getcommunication() {
        var resp=await axios.get("https://bevonairservice.co.in/decon/api/communication");
        var data=resp.data;

        setList(data);
    }

    useEffect(()=>{
        getcommunication();
    },[])

    return(
        <>

        <div className="container-fluid bg-secondary ps-5 pe-0 d-none d-lg-block">
            <div className="row gx-0">
                <div className="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
                    <div className="d-inline-flex align-items-center">
                        <Link className="text-body py-2 px-3 border-end" to="/tos"><small>TOS</small></Link>
                        <Link className="text-body py-2 px-3 border-end" to="/privacy"><small>Privacy</small></Link>
                        <Link className="text-body py-2 px-3 border-end" to="/disclaimer"><small>Disclaimer</small></Link>
                    </div>
                </div>
                <div className="col-md-6 text-center text-lg-end">
                    <div className="position-relative d-inline-flex align-items-center bg-primary text-white top-shape px-5">
                        <div className="me-3 pe-3 border-end py-2">
                            <p className="m-0"><i className="fa fa-envelope-open me-2"></i>info@bevonairservice.co.in</p>
                        </div>
                        <div className="py-2">
                            <p className="m-0"><a style={{color: '#fff'}} href={"tel:"+list.phone1}><i className="fa fa-phone-alt me-2"></i>{list.phone1}</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    );
}
import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';


import Topbar from '../inc/Topbar';
import Navbar from '../inc/Navbar';
import Carousel from '../inc/Carousel';

import Footer from '../inc/Footer';
import axios from 'axios';

import $ from 'jquery';

// import {useNavigate} from "react-router-dom";

export default function Contact() {

    let [list,setList]=useState("");

    async function getcommunication() {
        var resp=await axios.get("https://bevonairservice.co.in/decon/api/communication");
        var data=resp.data;

        setList(data);
    }

    let [name,setName]=useState("");
    let [isname,setIsname]=useState(false);

    let [email,setEmail]=useState("");
    let [isemail,setIsemail]=useState(false);

    let [phone,setPhone]=useState("");
    let [isphone,setIsphone]=useState(false);

    let [subject,setSubject]=useState("");
    let [issubject,setIssubject]=useState(false);

    let [content,setContent]=useState("");
    let [iscontent,setIscontent]=useState(false);

    // let [msg,setMsg]=useState("");
    let [ismsg,setIsmsg]=useState(false);
    let [isbtn,setIsbtn]=useState(false);

    async function sendcontact() {

        var flag=1;

        if (name.length==0) {
            setIsname(true);
            flag=0;
        }
        if (email.length==0) {
            setIsemail(true);
            flag=0;
        }
        if (phone.length==0) {
            setIsphone(true);
            flag=0;
        }
        if (subject.length==0) {
            setIssubject(true);
            flag=0;
        }
        if (content.length==0) {
            setIscontent(true);
            flag=0;
        }
        
        if (flag==1) {
            var fd=new FormData();
            fd.append("name",name);
            fd.append("email",email);
            fd.append("phone",phone);
            fd.append("subject",subject);
            fd.append("content",content);
            var resp=await axios.post("https://bevonairservice.co.in/decon/api/contact",fd);
            var data=resp.data;

            setIsmsg(true);
            setIsbtn(true);
        }
        
        
    }
    
    useEffect(()=>{
        getcommunication();
    },[])
    
    return(
        <>
        <Topbar/>
        <Navbar/>
        {/* <Carousel/> */}

    <div className="container-fluid bg-dark p-5">
        <div className="row">
            <div className="col-12 text-center">
                <h1 className="display-4 text-white">Contact Us</h1>
                <Link to="/">Home</Link>
                <i className="fas fa-greater-than text-primary px-2"></i>
                <Link to="/successstory">Contact Us</Link>
            </div>
        </div>
    </div>

    {/* <div id="about" className="about-area area-padding">
    <div className="container">
        <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="section-headline text-center">
                <h2><span style={{color: '#db1802'}}>Contact</span> Us</h2>
            </div>
        </div>
        </div>
    </div>
    </div> */}

    <div id="contact" className="contact-area p-4">
            <div className="contact-inner area-padding">
                <div className="contact-overly"></div>
                <div className="container ">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="section-headline text-center">
                        <h2>Contact us</h2>
                    </div>
                    </div>
                </div>
                <div className="row">
                    
                    <div className="col-md-4">
                    <div className="contact-icon text-center">
                        <div className="single-icon">
                        <i className="bi bi-phone"></i>
                        <p>
                            Call: +91-{list.phone1} / +91-{list.phone2}<br/>
                            <span>Monday-Saturday (10am-6pm)</span><br/>
                            <span>Sunday Closed</span>
                        </p>
                        </div>
                    </div>
                    </div>
                    
                    <div className="col-md-4">
                    <div className="contact-icon text-center">
                        <div className="single-icon">
                        <i className="bi bi-envelope"></i>
                        <p>
                            Email: {list.emailid}<br/>
                            <span>Web: www.bevonairservice.co.in</span>
                        </p>
                        </div>
                    </div>
                    </div>

                    <div className="col-md-4">
                    <div className="contact-icon text-center">
                        <div className="single-icon">
                        <i className="bi bi-geo-alt"></i>
                        <p>
                            {list.address_line1}<br/>
                            {list.address_line2}<br/>
                            <span>{list.city}, Pin-{list.pincode}, {list.state}<br/>
                            </span>
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col-md-6">
                    
                    <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=3rd Avenue, Main Lane 1, EE Block, Salt Lake, Bidhannagar,Kolkata,Kolkata,700091,West Bengal&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near" title="3rd Avenue, Main Lane 1, EE Block, Salt Lake, Bidhannagar,Kolkata,Kolkata,700091,West Bengal" aria-label="3rd Avenue, Main Lane 1, EE Block, Salt Lake, Bidhannagar,Kolkata,Kolkata,700091,West Bengal " width="100%" height="400px" style={{marginTop: '0px', border: '1px dotted #000'}}></iframe>

                    </div>

                    <div className="col-md-6">

                    {ismsg?<div className='alert alert-success text-center' style={{margin: '20px'}}>Message Sent Success!!</div>:''}
                    
                    <div className="form contact-form">
                        {/* <form action="forms/contact.php" method="post" role="form" className="php-email-form"> */}
                        <div className="form-group">
                            <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" onChange={(ev)=>{
                                setName(ev.target.value);
                                if (ev.target.value.length>0) {
                                    setIsname(false);
                                }
                            }}/>
                            {isname?<div className="text text-danger"><i className='fa fa-star' style={{color: 'red'}}></i> Name Required</div>:''}
                        </div>
                        <div className="form-group mt-3">
                            <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" onChange={(ev)=>{
                                setEmail(ev.target.value);
                                if (ev.target.value.length>0) {
                                    setIsemail(false);
                                }
                            }}/>
                            {isemail?<div className="text text-danger"><i className='fa fa-star' style={{color: 'red'}}></i> Email Required</div>:''}
                        </div>
                        <div className="form-group mt-3">
                            <input type="tel" className="form-control" name="phone" id="phone" placeholder="Your Phone Number" onChange={(ev)=>{
                                setPhone(ev.target.value);
                                if (ev.target.value.length>0) {
                                    setIsphone(false);
                                }
                            }}/>
                            {isphone?<div className="text text-danger"><i className='fa fa-star' style={{color: 'red'}}></i> Phone Number Required</div>:''}
                        </div>
                        <div className="form-group mt-3">
                            <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" onChange={(ev)=>{
                                setSubject(ev.target.value);
                                if (ev.target.value.length>0) {
                                    setIssubject(false);
                                }
                            }}/>
                            {issubject?<div className="text text-danger"><i className='fa fa-star' style={{color: 'red'}}></i> Subject Required</div>:''}
                        </div>
                        <div className="form-group mt-3">
                            <textarea className="form-control" name="message" rows="5" placeholder="Message" onChange={(ev)=>{
                                setContent(ev.target.value);
                                if (ev.target.value.length>0) {
                                    setIscontent(false);
                                }
                            }}/>
                            {iscontent?<div className="text text-danger"><i className='fa fa-star' style={{color: 'red'}}></i> Some Content Required</div>:''}
                        </div>
                        {/* <div className="my-3">
                            <div className="loading">Loading</div>
                            <div className="error-message"></div>
                            <div className="sent-message">Your message has been sent. Thank you!</div>
                        </div> */}
                        <div className="form-group mt-3 text-center"><button className='btn btn-primary' onClick={sendcontact} disabled={isbtn}>Send Message</button></div>
                        {/* </form> */}
                    </div>
                    </div>

                </div>
                </div>
            </div>
            </div>   


    
        <Footer/>
        </>
    );

}
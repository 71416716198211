// import logo from './logo.svg';
import './App.css';

import Main from './layout/Main';
import About from './layout/About';
import Airlinescsa from './layout/Airlinescsa';
import Cabincrew from './layout/Cabincrew';
import Groundstaff from './layout/Groundstaff';
import Baggagehandler from './layout/Baggagehandler';
import Retail from './layout/Retail';
// import Airlinesreservation from './layout/Airlinesreservation';
import Termsofuse from './layout/Termsofuse';
import Disclaimer from './layout/Disclaimer';
import Successstory from './layout/Successstory';
import Contact from './layout/Contact';
// import Signup from './layout/Signup';
// import Thankyou from './layout/Thankyou';
// import Newsignup from './layout/Newsignup';

// import Footer from './inc/Footer';

import {BrowserRouter,Routes,Route} from 'react-router-dom';

function App() {

  return (
    <>
      <BrowserRouter>
      
      <Routes>
        <Route exact path='/' element={<Main/>}/>
        <Route exact path='/about' element={<About/>}/>
        <Route exact path='/airlines-csa' element={<Airlinescsa/>}/>
        <Route exact path='/cabin-crew' element={<Cabincrew/>}/>
        <Route exact path='/ground-staff' element={<Groundstaff/>}/>
        <Route exact path='/baggage-handler' element={<Baggagehandler/>}/>
        <Route exact path='/retail' element={<Retail/>}/>
        <Route exact path='/successstory' element={<Successstory/>}/>
        <Route exact path='/contact' element={<Contact/>}/>
        <Route exact path='/tos' element={<Termsofuse/>}/>
        <Route exact path='/disclaimer' element={<Disclaimer/>}/>
        {/* <Route exact path='/airlines-reservation' element={<Airlinesreservation/>}/>
        
        
        
        
        <Route exact path='/signup-none' element={<Signup/>}/>
        <Route exact path='/thankyou' element={<Thankyou/>}/>
        <Route exact path='/newsignup' element={<Newsignup/>}/> */}

      </Routes>
      
      {/* <Footer/> */}
      </BrowserRouter>
    </>
  );
}

export default App;

import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import $ from 'jquery';

export default function Navbar() {


    let [iconlist,setIconlist]=useState('');
    let [iconx,setIconx]=useState('');

    let [activehome, setActivehome]=useState("");
    let [activeabout, setActiveabout]=useState("");
    let [activeserv, setActiveserv]=useState("");
    let [activeportf, setActiveportf]=useState("");
    let [activeteam, setActiveteam]=useState("");
    let [activecontact, setActivecontact]=useState("");
    let [activesuccessstory, setActivesuccessstory]=useState("");
    let [activesignup, setActivesignup]=useState("");

    let [activeother, setActiveother]=useState("");

    useEffect(()=>{
        if(window.location.pathname=='/') {
            setActivehome("nav-link scrollto active");
        } else if(window.location.pathname=='/about') {
            setActiveabout("nav-link scrollto active");
        } else if(window.location.pathname=='/portfolio') {
            setActiveportf("active");
        } else if(window.location.pathname=='/team') {
            setActiveteam("active");
        } else if(window.location.pathname=='/contact') {
            setActivecontact("active");
        } else if(window.location.pathname=='/portfolio') {
            setActiveportf("active");
        } else if(window.location.pathname=='/terms-of-use') {
            setActiveother("active");
        } else if(window.location.pathname=='/disclaimer') {
            setActiveother("active");
        } else if(window.location.pathname=='/successstory') {
            setActivesuccessstory("active");
        } else if(window.location.pathname=='/signup') {
            setActivesignup("active");
        } else {
            setActiveserv("active");
        }
        
        setIconlist('bi bi-list mobile-nav-toggle');

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

    },[])

    return(
        <>

        <nav className="navbar navbar-expand-lg bg-white navbar-light shadow-sm px-5 py-3 py-lg-0">
            <Link to="/" className="navbar-brand p-0">
                <h1 className="m-0 text-uppercase text-primary">
                    {/* <i className="far fa-smile text-primary me-2"></i>
                    consult */}
                    <Link to="/"><img src="/assets/img/logo.png" className="img-fluid"/></Link>
                </h1>
            </Link>
            <div className="navbar-toggler___" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" style={{border: '0px solid #fff'}}>
                <span className="navtogglemobile navbar-toggler-icon position-absolute"></span>
            </div>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto py-0 me-n3">
                    <Link to="/" className={"nav-item nav-link "+activehome}>Home</Link>
                    <Link to="/about" className={"nav-item nav-link "+activeabout}>About</Link>
                    
                    <div className="nav-item dropdown">
                        <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Job Role</Link>
                        <div className="dropdown-menu m-0">
                            <Link to="/airlines-csa" className="dropdown-item">Airlines CSA</Link>
                            <Link to="/cabin-crew" className="dropdown-item">Cabin Crew</Link>
                            <Link to="/ground-staff" className="dropdown-item">Ground Staff</Link>
                            <Link to="/baggage-handler" className="dropdown-item">Baggage Handler</Link>
                            <Link to="/retail" className="dropdown-item">Retail</Link>
                        </div>
                    </div>
                    <Link to="/successstory" className={"nav-item nav-link "+activesuccessstory}>Success Story</Link>
                    <Link to="/contact" className={"nav-item nav-link "+activecontact}>Contact</Link>

                    

                </div>
                
                <div style={{marginLeft: '20px'}}>
                    <button className="btn btn-warning btn-sm" id="login" onClick={()=>{
                        window.open('https://www.bevonairservice.co.in/joinportal/login.php');
                    }} >Log In</button>
                </div>
            </div>
        </nav>

        </>
    );
}
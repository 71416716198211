import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';


import Topbar from '../inc/Topbar';
import Navbar from '../inc/Navbar';
import Carousel from '../inc/Carousel';

import Footer from '../inc/Footer';

import $ from 'jquery';

// import {useNavigate} from "react-router-dom";

export default function Retail() {

    // let navigate = useNavigate();
    
    return(
        <>
        <Topbar/>
        <Navbar/>
        {/* <Carousel/> */}

    <div className="container-fluid bg-dark p-5">
        <div className="row">
            <div className="col-12 text-center">
                <h1 className="display-4 text-white">Airport Retail</h1>
                <Link to="/">Home</Link>
                <i className="fas fa-greater-than text-primary px-2"></i>
                <Link to="/retail">Airport Retail</Link>
            </div>
        </div>
    </div>


        {/* <!-- About Start --> */}
    <div className="container-fluid bg-secondary p-2">
        <div className="row g-0">
            <div className="col-lg-6 py-6 px-5">
                <div className="well-middle">
                    <div className="single-well">
                    <Link to="#">
                    <h4 className="sec-head">Career as a Airline Airport Retail Jobs</h4>
                    </Link>
                    <p>
                    We are hiring candidates, vacancy for airport retails executive on PAN INDIA candidates. Hiring Fresher Higher Secondary Pass people in leading airlines companies.
                    </p>
                    <p>
                    As the public face of the company the Retail Associate is responsible for dealing with any and all customer questions about the products and services the company offers. We are hiring for a big retail brand.
                    </p>
                    <ul>
                        <li>
                            <i class="bi bi-check"></i> Greeting customers, responding to questions, improving engagement with merchandise and providing outstanding customer service
                        </li>
                        <li>
                            <i class="bi bi-check"></i> Directing customers to merchandise within the store
                        </li>
                        <li>
                            <i class="bi bi-check"></i> Maintaining an orderly appearance throughout the sales floor
                        </li>
                        <li>
                            <i class="bi bi-check"></i> Introducing promotions and opportunities to customers
                        </li>
                        <li>
                            <i class="bi bi-check"></i> Handle kids who will come into the stores to purchase toys
                        </li>
                    </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 p-2">
            <div className="well-left">
              <div className="single-well" style={{paddingTop: '40px'}}>
                <Link to="/signup">
                  <img src="assets/img/about/airlines-decon.jpg" style={{width: '100%'}} alt=""/>
                </Link>
              </div>
            </div>
            </div>

            <div className="col-12 py-0 px-5">
                  {/* <div className="well-middle">
                  <div className="single-well"> */}
                    <p style={{fontSize:'large', fontWeight: '700', marginBottom:'0', paddingTop: '30px'}}>Eligibility to Become Airline Airport Retail</p>
                    <p className="text-justify" style={{fontSize:'11pt', paddingTop: '20px'}}>We have urgent requirements for the profile of Airport Retail with leading airlines.</p>
                    <p className="text-left" style={{paddingTop: '0px'}}>
                      <ul>
                        <li>
                          <i class="bi bi-check"></i> Position: Airport Retail
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Age: 18-35 yrs
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Location: PAN India.
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Qualification: 12th pass or above.
                        </li>

                        <li>
                          <i class="bi bi-check"></i> Job Types: Full-time, Fresher
                        </li>
                      </ul>
                    </p>
                    <p className="text-justify" style={{paddingTop: '20px'}}><b>Skills Required:</b> Some of the necessary skills required to become an Airline Airport Retail are </p>
                    <p className="text-left" style={{fontWeight: '300', paddingTop: '0px'}}>
                      <ul>
                      <li>
                          <i class="bi bi-check"></i> Good understanding of Ground support equipment handling
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Good incentives
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Candidate will be hired on the company payroll
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Employment Type: Full Time, Permanent
                        </li>
                        <li>
                          <i class="bi bi-check"></i> Freshers can also apply
                        </li>
                      </ul>
                    </p>
                 {/* </div>
                 </div>    */}
                </div>

        </div>

    </div>


    
        <Footer/>
        </>
    );

}